<template>
  <g>
    <g
      style="fill:#840000; fill-opacity:0.0;  stroke:#840000; stroke-width:60; stroke-opacity:1;  stroke-linecap:round; stroke-linejoin:round; 
        font-family: monospace;"
      id="g14"
      v-for="(coordinate, index) in points"
      :key="index"
      :class="`drag${coordinate.pk}`"
    >
      <circle :cx="coordinate.x * svgScale" :cy="coordinate.y * svgScale" r="300" id="circle10" />
      <path
        :d="
          `M${coordinate.x * svgScale} ${(coordinate.y * svgScale) + 300} L${
            coordinate.x * svgScale
          } ${(coordinate.y * svgScale) + 1300}`
        "
        id="path12"
      />
      <text
        :x="(coordinate.x  * svgScale)"
        :y="(coordinate.y * svgScale) - (300 * 3) "
        :stroke="coordinate.dut === 0 ? 'black' : 'red'"
        stroke-width="100"
        :font-size="`${svgScale / 3}px`"
        text-anchor="middle"
      >
        {{ coordinate.ref }}
      </text>
      <text
        :x="(coordinate.x  * svgScale)"
        :y="(coordinate.y * svgScale) + (1300 + 1500) "
        :stroke="coordinate.dut === 0 ? 'black' : 'red'"
        stroke-width="100"
        :font-size="`${svgScale / 3}px`"
        text-anchor="middle"
      >
        {{ coordinate.net }}
      </text>
    </g>
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "TestPointView",
  props: {
    testpointPlot: Array,
    renderScale: Number,
    svgScale: Number,
  },
  data() {
    return {
      points: JSON.parse(JSON.stringify(this.testpointPlot)),
    };
  },
  methods: {
    activateDragAndDrop() {
      this.points.forEach((o, index) => {
        interact(`.drag${o.pk}`).draggable({
          onmove: (event) => {
            this.points[index].x += (event.dx / this.svgScale) * (this.renderScale/2);
            this.points[index].y += (event.dy / this.svgScale) * (this.renderScale/2);

            event.target.style.transform = `translate(${this.points[index].x}px, ${this.points[index].y}px)`;
          },
        });
      });
    },
  },
  mounted() {
    this.activateDragAndDrop(false);
  },
};
</script>
